.article_panel {
	width: 100%;
	max-width: 50rem;

	display: flex;
	flex-flow: column;
	gap: 8px;
}

.article_panel.hidden {
	display: none;
}

#article_post_time {
	color: #555;
	font-size: 0.875rem;
	font-weight: 500;
}

#article_content {
	font-size: 0.9375rem;
	font-weight: 400;
}

#article_content a {
	color: blue;
}