.header {
	width: 100%;
	height: fit-content;
	padding: 12px 0;
	box-sizing: border-box;

	color: #fff;
	background-color: var(--dark-green);
	/* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px; */
	box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;

	font-family: "Uncial Antiqua", system-ui;
	font-size: 1.5rem;
	font-weight: 400;
	font-style: normal;
}

.header_content {
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto;
	box-sizing: border-box;
	padding: 0 32px;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;
}

.header_separator {
	width: 1px;
	height: 80%;

	background-color: #fff;
}

.header_label {
	flex: 1 0 0;

	display: flex;
	align-items: center;
	gap: 4px;

	cursor: pointer;
}

.header_icon {
	width: 2rem;
	height: 2rem;
}

.header_actions {
	flex: 1 0 0;

	display: flex;
	flex-flow: row wrap;
	justify-content: end;
}

.header_button {
	padding: 12px 16px;

	font-family: inherit;
}

@media (max-width: 1200px) {
	.header_label {
		flex: none;
		margin-right: 16px;
	}
}

@media (max-width: 420px) {
	.header_content {
		flex-flow: column;
	}

	.header_actions {
		margin-top: 16px;

		justify-content: center;
	}
}