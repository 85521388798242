.spinner {
	margin: 10px auto;
	width: 50px;
	height: 40px;
	text-align: center;
	font-size: 10px;
}

.spinner>div {
	background-color: var(--dark-green);
	height: 100%;
	width: 6px;
	display: inline-block;

	-webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
	animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .spinner_r2 {
	-webkit-animation-delay: -1.1s;
	animation-delay: -1.1s;
}

.spinner .spinner_r3 {
	-webkit-animation-delay: -1.0s;
	animation-delay: -1.0s;
}

.spinner .spinner_r4 {
	-webkit-animation-delay: -0.9s;
	animation-delay: -0.9s;
}

.spinner .spinner_r5 {
	-webkit-animation-delay: -0.8s;
	animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {

	0%,
	40%,
	100% {
		-webkit-transform: scaleY(0.4)
	}

	20% {
		-webkit-transform: scaleY(1.0)
	}
}

@keyframes sk-stretchdelay {

	0%,
	40%,
	100% {
		transform: scaleY(0.4);
		-webkit-transform: scaleY(0.4);
	}

	20% {
		transform: scaleY(1.0);
		-webkit-transform: scaleY(1.0);
	}
}