.modal_wrapper {
	position: fixed;
	width: 100dvw;
	height: 100dvh;
	top: 0;
	left: 0;

	display: flex;
	align-items: center;
	justify-content: center;

	background-color: rgba(0, 0, 0, 0.875);
}