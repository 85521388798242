.login_modal {
	display: flex;
	flex-flow: column;
	align-items: center;
	gap: 10px;
}

.login_modal_form {
	display: flex;
	flex-flow: column;
	align-items: center;
	gap: 10px;
}