:root {
	--dark-green: #004d44;
}

body {
	margin: 0;
	overflow-y: scroll;

	background-color: rgb(240, 240, 240);

	font-family: "Baloo Paaji 2", serif;
	font-weight: 400;
	font-style: normal;
	line-height: 1;
}

p {
	margin: 0;
}

a {
	color: inherit;
	text-decoration: none;
}

option {
	font-size: 0.9375rem;
	font-weight: bold;
}