.job_details {
	width: 100%;
	max-width: 720px;

	display: flex;
	flex-flow: column;
	gap: 10px;
}

.job_details_title {
	font-size: 1.25rem;
	font-weight: 600;
}

.job_details_subtitle {
	display: block;
	padding-top: 10px;
	border-top: 1px solid #eee;

	line-height: 1.2;
	font-size: 1rem;
	font-weight: 500;
}

.job_details_poster {
	line-height: 1.2;
	font-size: 1rem;
	font-weight: 500;
}

.job_details_desc {
	font-size: 1rem;
	font-weight: 400;
}

.job_details_actions {
	margin-top: 4px;
	padding-top: 8px;
	border-top: 1px solid #eee;

	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	gap: 8px;
}

.actions_left {
	display: flex;
	flex-flow: row wrap;
	justify-content: start;
	gap: 8px;
}

.actions_right {
	display: flex;
	flex-flow: row wrap;
	justify-content: end;
	gap: 8px;
}