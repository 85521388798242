.page {
	/* background-color: red; */

	width: 100%;
	max-width: 1200px;
	height: fit-content;
	margin: 32px auto;
	padding: 0 32px;
	box-sizing: border-box;

	display: flex;
	flex-flow: column;
	align-items: center;
	gap: 16px;
}

.page_content {
	width: 100%;

	display: flex;
	flex-flow: column;
	align-items: center;
	gap: 16px;
}

.page_content.hidden {
	display: none;
}

.page_header {
	display: flex;
	flex-flow: column;
	gap: 4px;
}

.page_actions {
	margin-left: auto;
	margin-bottom: 16px;

	display: flex;
	flex-flow: row wrap;
	gap: 8px;
}