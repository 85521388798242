.item_filter_panel {
	width: 100%;
	max-width: 480px;

	display: flex;
	flex-flow: column;
	align-items: center;
	gap: 8px;
}

.item_filter_items {
	width: 100%;

	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	gap: 16px;
}

.item_filter_item {
	flex: 1 1 180px;

	display: flex;
	flex-flow: column;
	gap: 4px;
}

.item_list_content {
	width: 100%;
	padding: 16px 0;

	display: flex;
	flex-flow: row;
	gap: 16px;
}

.item_list_content_left {
	flex: 1 1 0;

	display: flex;
	flex-flow: column;
	gap: 16px;
}

.item_list_content_right {
	height: fit-content;
	flex: 1 1 0;
}

.item_list_base_label {
	display: block;
	width: 100%;
	margin: 16px 0;

	color: #555;
	font-size: 1.125rem;
	font-weight: 500;
	text-align: center;
}

.item_list_items_container {
	flex: 1 1 0;

	display: flex;
	flex-flow: column;
	gap: 16px;
}

.item_list_items_container.hidden {
	display: none;
}

.item_list_item {
	width: 100%;

	display: flex;
	flex-flow: column;
	gap: 4px;
}

.item_list_item_title {
	font-size: 1.0625rem;
	font-weight: 600;

	cursor: pointer;
}

.item_list_item_title:hover {
	text-decoration: underline;
}

.item_list_item_subtitle {
	font-size: 1rem;
	font-weight: 500;
}

.item_list_item_description {
	margin-top: 4px;
	padding-top: 8px;
	border-top: 1px solid #eee;

	font-size: 1rem;
}

.item_list_item_description.hidden {
	display: none;
}

.item_list_item_actions {
	margin-top: 4px;
	padding-top: 8px;
	border-top: 1px solid #eee;

	display: flex;
	flex-flow: row wrap;
	justify-content: end;
	gap: 8px;
}

@media (max-width: 480px) {
	.item_list_content_right {
		display: none;
	}
}