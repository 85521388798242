.home_panel {
	max-width: 720px;
}

.home_panel_label {
	line-height: 1.125;
	text-align: center;
}

.home_panel_actions {
	margin-top: 16px;

	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	gap: 8px;
}

.home_content {
	width: 100%;

	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	gap: 16px;
}

.home_news_panel {
	flex: 1 1 100px;
	min-width: 400px;

	display: flex;
	flex-flow: column;
	gap: 8px;
}

.home_news_panel_buttons {
	display: flex;
	flex-flow: column;
	gap: 4px;
}

.home_news_button {
	width: 100%;
	height: fit-content;
	padding: 10px 14px;
	border-radius: 8px;
	background-color: #fff;
	box-sizing: border-box;

	border: none;
	outline: none;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;
	gap: 20px;

	font-size: 1rem;
	font-family: inherit;
	line-height: inherit;

	cursor: pointer;
	user-select: text;
}

.home_news_button:hover {
	background-color: #eee;
}

.home_news_button_marker {
	width: 8px;
	height: 8px;
	border-radius: 50%;

	background-color: rgb(40, 180, 255);
}

.home_news_button_title {
	max-width: 24rem;

	font-weight: 500;
	text-align: left;
}

.home_news_button_time {
	color: #555;
	font-size: 0.875rem;
	text-wrap: nowrap;
	text-align: right;
}

.home_news_panel_view_more {
	text-align: center;
}

@media (max-width: 480px) {
	.home_content {
		flex-flow: column;
	}
	
	.home_news_panel {
		width: 100%;
		min-width: 0;
	}

	.home_news_button {
		flex-flow: column;
		align-items: start;
		gap: 5px;
	}

	.home_news_button_time {
		text-align: left;
	}
}