.item_list_user_component {
	width: 100%;

	display: flex;
	flex-flow: column;
	gap: 16px;
}

.user_component_panel {
	width: 100%;

	display: flex;
	flex-flow: column;
	gap: 16px;
}

.user_component_title {
	font-size: 1.25rem;
	font-weight: 600;
}

.user_component_subtitle {
	font-size: 1rem;
	font-weight: 500;
	line-height: 1;
}

.user_component_description_content {
	font-size: 1rem;
	font-weight: 400;
}

.user_component_actions {
	margin-top: 4px;
	padding-top: 8px;
	border-top: 1px solid #eee;
	
	display: flex;
	flex-flow: row wrap;
	justify-content: end;
	gap: 4px;
}

.user_component_panel_grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(96px, 1fr));
	gap: 8px;
}

.user_component_gallery_image {
	width: 100%;
	padding: 32px;
	box-sizing: border-box;

	border-radius: 8px;
	background-color: #eee;

	cursor: pointer;
}