.dropdown_test_wrapper {
	position: fixed;
	height: fit-content;
	top: 0;
	left: 0;
}

.dropdown_background {
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: -1;
	border-radius: 0px 0px 8px 8px;

	background-color: #eee;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.dropdown_input_container {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	justify-content: start;
	gap: 4px;
}

.dropdown_input_selection {
	font-size: 0.9375rem;
	font-weight: 500;

	cursor: pointer;
	user-select: none;
}

.dropdown_input_field {
	flex: 1 1 0;
	margin-left: 4px;
	
	background: none;
	border: none;
	outline: none;

	line-height: inherit;
	font-size: 1rem;
	font-weight: 500;
	font-family: inherit;
}

.dropdown_input_selection {
	width: fit-content;
	height: fit-content;
	display: inline-block;
	border-radius: 8px;
	padding: 6px 14px;

	color: #fff;
	background-color: var(--dark-green);
}

.dropdown_options {
	padding: 0px 4px 4px 4px;
	overflow-y: auto;
	margin-right: 8px;
	
	display: flex;
	flex-flow: column;
}

.dropdown_button {
	width: 100%;
	height: fit-content;
	padding: 10px;
	border-radius: 8px;
	background-color: #eee;

	border: none;
	outline: none;

	text-align: left;
	font-size: 0.9375rem;
	font-weight: 500;
	font-family: inherit;
	line-height: inherit;

	cursor: pointer;
}

.dropdown_button:hover {
	filter: brightness(1.05);
}

.dropdown_none_label {
	padding: 10px;

	color: #555;
	font-size: 0.9375rem;
	font-weight: 500;
	font-family: inherit;
	line-height: inherit;
}

.dropdown_none_label.hidden {
	display: none;
}