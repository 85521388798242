#bookmark_container {
	width: 100%;
	max-width: 720px;

	display: flex;
	flex-flow: column;
	gap: 8px;
}

.bookmark_item {
	width: 100%;

	display: flex;
	flex-flow: column;
	gap: 8px;
}

.bookmark_title {
	font-weight: 600;
	font-size: 1.25rem;
}

.bookmark_content {
	font-size: 1rem;
}