.panel {
	width: fit-content;
	height: fit-content;
	padding: 16px;
	box-sizing: border-box;

	border-radius: 8px;
	background-color: #fff;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 2px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.panel.hidden {
	visibility: hidden;
}

.panel_content {
	width: 100%;

	display: flex;
	flex-flow: column;
	gap: 8px;
}

.panel_button {
	display: block;
	width: fit-content;
	height: fit-content;
	padding: 10px 14px;
	background-color: var(--dark-green);
	border-radius: 8px;

	border: none;
	outline: none;

	color: #fff;
	font-size: .9375rem;
	font-weight: 500;
	font-family: inherit;
	line-height: 0.875;

	cursor: pointer;

	transition: 120ms ease-in filter;
}

.panel_button:hover {
	filter: brightness(1.2);
}

.panel_button.white {
	color: #000;
	background-color: #fff;
	/* border: 1px solid #ddd; */
}

.panel_button.dangerous {
	background-color: rgb(230, 42, 42);
}

.panel_button.disabled {
	background-color: #888;

	cursor: default;
}

.panel_button.disabled:hover {
	filter: none;
}

.panel_button.shadow {
	box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 2px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.panel_title {
	width: 100%;
	height: fit-content;
	display: block;

	text-align: center;
	font-size: 1.5rem;
	font-weight: 600;
	font-family: "Uncial Antiqua", system-ui;
}

.panel_subtitle {
	font-size: 0.9375rem;
	font-weight: 500;
}

.panel_subtitle.hidden {
	display: none;
}

.panel_header {
	margin-right: auto;

	font-size: 1.125rem;
	font-weight: 600;
}

.panel_header.fancy {
	font-family: "Uncial Antiqua", system-ui;
}

.panel_input_label {
	margin-left: 4px;
	margin-right: auto;
	
	color: #555;
	font-size: 0.875rem;
	font-weight: 500;
}

.panel_input_text {
	width: 100%;
	height: fit-content;
	padding: 4px 14px;
	margin: 0;
	box-sizing: border-box;

	background-color: #eee;
	border-radius: 8px;

	border: none;
	outline: none;

	line-height: 1;
	font-size: 1rem;
	font-weight: 500;
	font-family: inherit;
}

.panel_input_text.textarea {
	height: calc(1rem + 20px);
	box-sizing: border-box;
	margin-right: 8px;
	padding: 10px;
	resize: none;
}

.panel_input_text.error {
	outline: 1px solid rgb(230, 42, 42);
}

/* .panel_input_text.expandable:focus {
	height: 5rem;
} */

.panel_input_text:focus {
	box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 2px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.panel_input_dropdown {
	width: 100%;
	height: fit-content;
	padding: 4px 8px;

	border: none;
	outline: none;
	border-radius: 8px;
	background-color: #eee;

	font-size: 1rem;
	font-weight: 500;
	font-family: "Baloo Paaji 2", Arial, Helvetica, sans-serif;
	text-align: left;
}

.panel_link {
	color: #0064d1;
	font-size: 1rem;
	font-weight: 600;

	cursor: pointer;
}

.panel_link:hover {
	text-decoration: underline;
}

.panel_link.dangerous {
	color: rgb(230, 42, 42);
}

.panel_link.hidden {
	display: none;
}