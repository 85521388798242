.user_content_right {
	flex: 1 1 50%;
	height: fit-content;

	display: flex;
	flex-flow: column;
	gap: 16px;
}

.user_profile_input {
	font-size: 0.9375rem;
}

.user_new_post {
	width: 100%;
	height: fit-content;

	display: flex;
	flex-flow: column;
	gap: 8px;
}

.user_new_post_actions {
	width: 100%;

	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	gap: 8px;
}

.user_new_post_actions.left {
	width: fit-content;
	justify-content: start;
}

.user_new_post_actions.right {
	width: fit-content;
	justify-content: end;
}

.no_posts_label {
	margin-top: 16px;

	color: #555;
	font-size: 1.125rem;
	font-weight: 500;
	text-align: center;
}

.user_post {
	width: 100%;

	display: flex;
	flex-flow: column;
	gap: 4px;
}

#user_post_time {
	color: #555;
	font-size: 0.875rem;
	font-weight: 500;
}

#user_post_content {
	color: #000;
	font-size: 0.9375rem;
}

.contractor_profile {
	display: flex;
	flex-flow: row wrap;
	gap: 16px;
}

.contractor_profile.loading {
	display: none;
}

.contractor_profile_header {
	width: 100%;

	display: flex;
	flex-flow: row wrap;
	align-items: start;
	justify-content: space-between;
	gap: 16px;
}

.contractor_profile_header_details {
	width: fit-content;

	display: flex;
	flex-flow: row;
	gap: 8px;
}

.contractor_profile_header_details_labels {
	display: flex;
	flex-flow: column;
	gap: 4px;

	padding-left: 8px;
	border-left: 1px solid #eee;
}

.contractor_profile_header_label.name {
	font-size: 1.125rem;
	font-weight: 600;
}

.contractor_profile_header_label.role {
	font-size: 1rem;
	font-weight: 500;
}

.contractor_profile_image {
	width: 64px;
	height: 64px;
	padding: 8px;
	box-sizing: border-box;

	border-radius: 50%;
	background-color: #eee;
}

.contractor_profile_header_actions {
	margin-top: auto;

	display: flex;
	flex-flow: row wrap;
	align-items: end;
	justify-content: end;
	gap: 8px;
}

.contractor_profile_content {
	width: 100%;

	display: flex;
	flex-flow: row wrap;
	gap: 16px;
}

.contractor_profile_content_panel {
	width: 100%;
	height: fit-content;

	display: flex;
	flex-flow: column;
	gap: 8px;
}

.contractor_profile_content_panel_header {
	width: 100%;

	display: flex;
	flex-flow: row;
	align-items: end;
	justify-content: space-between;
	gap: 20px;
}

.contractor_profile_content_panel_title {
	margin-right: auto;

	font-size: 1.125rem;
	font-weight: 600;
}

.contractor_profile_content_left {
	flex: 1 1 35%;
	min-width: 280px;
	height: fit-content;

	display: flex;
	flex-flow: column;
	gap: 16px;
}

.contractor_profile_description {
	font-size: 0.9375rem;
	line-height: 1.0625;
}

.contractor_profile_contact_info {
	padding-top: 12px;
	border-top: 1px solid #eee;

	display: flex;
	flex-flow: column;
	gap: 4px;
}

#contact_edit_button {
	user-select: none;
}

.contractor_profile_contact_button {
	padding: 4px 8px;

	background-color: #fff;

	border: none;
	outline: none;
	border-radius: 8px;

	display: flex;
	align-items: center;
	justify-content: start;
	gap: 8px;

	text-align: left;
	font-size: 0.875rem;
	font-weight: 500;
	font-family: inherit;

	cursor: pointer;
	user-select: text;
}

.contractor_profile_contact_button:hover {
	background-color: #eee;
}

.contractor_profile_contact_button_icon {
	width: 20px;
	height: 20px;
}

.contractor_profile_contact_button_edit {
	padding: 4px 8px;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: start;
	gap: 8px;
}

.contractor_profile_content_panel_label {
	width: fit-content;
	padding: 8px 16px;
	border-radius: 8px;

	font-size: 0.9375rem;
	line-height: 1.0625;
}

.contractor_profile_content_panel_label.link {
	cursor: pointer;
}

.contractor_profile_content_panel_label.link:hover {
	background-color: #eee;
}

.contractor_profile_gallery_collection {
	margin-top: 8px;

	display: flex;
	flex-flow: row wrap;
	gap: 8px;
}

.contractor_profile_gallery_image {
	flex: 1 1 80px;
	aspect-ratio: 1;
	padding: 16px;

	border-radius: 8px;
	background-color: #eee;

	cursor: pointer;
}

.self_only.hidden {
	display: none;
}

.other_only.hidden {
	display: none;
}