.new_job_content {
	width: 100%;
	max-width: 720px;

	display: flex;
	flex-flow: column;
	gap: 10px;
}

.new_job_form {
	display: flex;
	flex-flow: column;
	gap: 10px;
}

.new_job_field_wrapper {
	width: 100%;
	height: fit-content;

	display: flex;
	flex-flow: column;
	gap: 4px;
}

.new_job_input_value_wrapper {
	display: flex;
	flex-flow: column;
	gap: 4px;
}

.new_job_input_value_label {
	font-size: 1rem;
	font-weight: 500;
}

.new_job_input_value {
	padding: 4px 10px;
	margin: 0;
	
	background-color: #eee;
	border-radius: 8px;

	border: none;
	outline: none;
	resize: none;

	font-size: 1rem;
	font-weight: 500;
	font-family: inherit;

	/* transition: ease-in 60ms box-shadow; */
}

.new_job_input_value.multiline {
	padding: 8px 10px;
	line-height: 1;
}

/* .new_job_input_value:focus {
	border-radius: 8px 8px 0px 0px;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
} */

.new_job_input_value.dropdown {
	padding: 3px 5px;

	cursor: text;
}

.new_job_input_value.dropdown:focus {
	border-radius: 8px 8px 0px 0px;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.new_job_actions {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	gap: 8px;
}