.signup_panel {
	width: 100%;
	max-width: 32rem;

	display: flex;
	flex-flow: column;
	align-items: center;
	gap: 10px;

	text-align: center;
}

.signup_field {
	width: 100%;
	height: fit-content;

	display: flex;
	flex-flow: column;
	gap: 4px;
}