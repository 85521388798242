.login_panel {
	width: 100%;
	max-width: 20rem;

	display: flex;
	flex-flow: column;
	align-items: center;
	gap: 10px;

	text-align: center;
}

.login_panel_form {
	width: 100%;
	
	display: flex;
	flex-flow: column;
	align-items: center;
	gap: 10px;
}